import * as React from 'react';
import { useMemo, useContext } from 'react';
import { Table } from 'react-bootstrap';
import { useTable, useSortBy, TableCellProps } from 'react-table';
import { createUseStyles } from 'react-jss';
import { DownloadButton } from '../components/DownloadButton';
import { PreviewButton } from './PreviewButton';
import { getColumns } from './utils';
import { useInView } from 'react-intersection-observer';
import { useEffect, useRef } from "react";
import { BeatLoader } from "react-spinners";
import { AssetsContext } from '..';

interface ScansTableData {
  tableData: any;
}

const useStyles = createUseStyles({
  tableWrapper: {
    '& > div': {
      paddingBottom: '100px'
    }
  }
});

export const ScansTable: React.FC<
    ScansTableData & {
    sortHandler: any;
    isSortedDesc: boolean;
    isFullyLoaded: boolean;
    pagingHandler: any;
    scrollHeight: number;
    scrollPositionHandler: any;
}> = ({
  tableData,
  sortHandler,
  isSortedDesc,
  isFullyLoaded,
  pagingHandler,
  scrollHeight,
  scrollPositionHandler
}) => {
  const tableRef = useRef(null);
  const { behavior_list: { show_handle_contact_only } } = useContext(AssetsContext)

  const onChange = (inView, entry) => {
    if (inView)
      pagingHandler();
  };
  const { ref, inView, entry } = useInView({
    onChange
  });

  const columns = useMemo(() => getColumns(show_handle_contact_only), [show_handle_contact_only])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: tableData,
        initialState: {
          sortBy: [
            {
              id: 'date',
              desc: isSortedDesc
            }
          ]
        }
      },
      useSortBy
    );

  const styles = useStyles();

  useEffect(() => {
     if (tableRef.current)
       tableRef.current.scrollTop = scrollHeight;
    return () => {
      // This function will be called when the component is about to be unmounted
      if (tableRef.current)
        scrollPositionHandler(tableRef.current.scrollTop)
    };
  }, []);

  return (
    <div className={styles.tableWrapper}>
      <Table {...getTableProps()} responsive style={{ minWidth: '750px' }} ref={tableRef}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any, k) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={k}
                  onClick={() => {
                    column.toggleSortBy(!column.isSortedDesc);
                    sortHandler(column.id, !column.isSortedDesc);
                  }}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any, i) => {
            prepareRow(row);
            const itemProps = i === rows.length - 1 ? {'ref': ref} : {};
            return (
              <tr key={`${row.cells.concat}${i}`} {...row.getRowProps()} {...itemProps}>
                {row.cells.map((cell) => {
                  const cellProps: TableCellProps = cell.getCellProps();
                  if (cell.column.id === 'scanId') {
                    return (
                      <td key={cellProps.key}>
                        <div className="d-flex justify-content-end">
                          {/* <PreviewButton
                            scanId={cell.value}
                            userId={row.original.userId}
                          /> */}
                          <DownloadButton
                            scanId={cell.value}
                            userId={row.original.userId}
                            date={row.original.date}
                            contact={row.original.contact}
                          />
                        </div>
                      </td>
                    );
                  }

                  return <td key={cellProps.key}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {!isFullyLoaded
        && <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <BeatLoader />
      </div>}
      {rows.length === 0 && isFullyLoaded && (
        <h4>No results available</h4>
      )}
    </div>
  );
};
