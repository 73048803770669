export const getColumns = (show_handle_contact_only: string) => [
  {
    Header: 'Date',
    accessor: 'date'
  },
  ...(show_handle_contact_only === 'true' ? [
    {
      Header: 'Subject ID',
      accessor: 'subjectid',
      disableSortBy: true
    }
  ] : [
    {
      Header: 'User Name',
      accessor: 'userName',
      disableSortBy: true
    },
    {
      Header: 'Contact',
      accessor: 'contact',
      disableSortBy: true
    }
  ]),
  {
    accessor: 'scanId'
  }
];

const padTo2Digits = (num) => {
  return num.toString().padStart(2, '0');
};

export const getDateWithCorrectFormat = (date) => {
  const dateWithCorrectFormat = (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate())
    ].join('-') +
    ' ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds())
    ].join(':')
  );
  return dateWithCorrectFormat;
};

type GenerateDataForTableParams = {
  scans: Array<any>
  users: any
  show_handle_contact_only: string
  appId: string
}


export const generateDataForTable = ({scans, users, show_handle_contact_only, appId}: GenerateDataForTableParams) => {
  // filter to only include scans with status Complete
  return scans.reduce((scansArr, scan) => {
    if (scan.status === 'complete'){
      scansArr.push({
        utcDate: scan.date,
        date: getDateWithCorrectFormat(new Date(scan.date)),
        ...(show_handle_contact_only === 'true' ? {
          subjectid: users?.[scan.user].handles[appId]?.[0]
        } : {
        userName: `${users?.[scan.user].firstName} ${users?.[scan.user].lastName}`,
        contact: users?.[scan.user].email || users?.[scan.user].phone
        }),
        scanId: scan.id,
        userId: scan.user
      });
    }
    return scansArr;
  }, []);
};
